import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Button from '../components/Button';
import HubspotEmbedForm from "../components/HubspotEmbedForm";

const TopBanner = () => {

  const data = useStaticQuery(graphql`
  query {
    block: allBlock {
      nodes {
        title
        url
        buttonname
        position
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`);

const topBlock = data.block.nodes.filter((i) => i.position === "Top" && i)

  return (
    <div className="bg-[#1EC7A5] text-white py-5">
      <div className="">
        <div className="flex justify-center items-center">
          <div className="typo-body mr-4">
          {topBlock[0].title}
          </div>
          <div>
            <Button to={topBlock[0].url} size="sm" variant="accent" theme="alt" className="border-r-none bg-black border-0">
            {topBlock[0].buttonname}
            </Button>
            {/*<HubspotEmbedForm hideClass formId="76a71927-4515-46f7-9bd9-e6d9d4da3abb" />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
