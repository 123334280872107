import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import React, { useRef } from 'react';

import Button from '../components/Button';
import Link from '../components/Link';
import NavLink from '../components/NavLink';

import ChevronDownIcon from '../components/icons/ChevronDownIcon';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';
import ChevronRightIcon from '../components/icons/ChevronRightIcon';
import MenuButton from '../components/MenuButton';

import ArrowRightIcon from '../components/icons/ArrowRightIcon';

import { useLayoutActions, useLayoutState } from './Context';

import useOutsideClick from '../hooks/useOutsideClick';

import LogoImageSrc from '../images/shared/logo.svg';

const sidebarVariants = {
  menuOpen: () => ({
    x: '0%',
    transition: { type: 'spring', stiffness: 120, damping: 20 },
  }),
  menuClosed: () => ({
    x: '100%',
    transition: { type: 'spring', stiffness: 120, damping: 15 },
  }),
};

const navVariants = {
  enter: { opacity: 0 },
  idle: { opacity: 1, transition: { staggerChildren: 0.07 } },
  exit: { opacity: 0 },
};

const itemVariants = {
  enter: { opacity: 0, x: '-5%' },
  idle: { opacity: 1, x: 0 },
  exit: { opacity: 0 },
};

const transition = {
  type: 'tween',
};

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainNavs: navigationYaml(yamlId: {eq: "/"}) {
        id: yamlId
        title
        items {
          title
          group
        }
      }
      platformNavs: navigationYaml(yamlId: {eq: "/platform"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      solutionNavs: navigationYaml(yamlId: {eq: "/solutions"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      industryNavs: navigationYaml(yamlId: {eq: "/industry"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      resourcesNavs: navigationYaml(yamlId: {eq: "/resources"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      companyNavs: navigationYaml(yamlId: {eq: "/company"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
    }
  `);

  const {
    mainNavs,
    platformNavs,
    solutionNavs,
    industryNavs,
    resourcesNavs,
    companyNavs
  } = data;

  const headerRef = useRef();

  const { activeGroup, menuOpen, isMediumScreen } = useLayoutState();
  const { setActiveGroup, closeMenu, toggleMenu } = useLayoutActions();

  const isActiveGroup = (group) => {
    return activeGroup === group;
  };

  const isActiveNested = (group) => {
    return activeGroup ? activeGroup.includes(group) : false;
  };

  const handleNav = (nav) => {
    if (nav.url) {
      navigate(nav.url);
      closeMenu();
    }

    if (isActiveGroup(nav.group)) {
      closeMenu();
    } else {
      setActiveGroup(nav.group);
    }
  }

  useOutsideClick(headerRef, closeMenu, true);

  return (
    <>
      <header className={classnames('relative z-50', { 'bg-[#FFFFFF] from-white to-gray-200': !activeGroup, 'bg-white': activeGroup, })} ref={headerRef}>
        <div className="container-lg">
          <div className="flex items-center justify-between h-24">
            <div className="flex items-center">
              <div className="min-w-[220px]">
                <Link to="/" onClick={() => { closeMenu(); }} className="block uppercase">
                  <img src={LogoImageSrc} alt="Logo" className="h-10" />
                </Link>
              </div>
              {!isMediumScreen && (
                <div className="xl:ml-12 ml-10 hidden lg:flex items-center justify-between">
                  {mainNavs.items.map((nav) => (
                    <div className="xl:mr-9 mr-5" key={nav.title}>
                      <Button
                        variant="reset"
                        size="reset"
                        className={classnames({
                          'hover:text-green-400': !isActiveGroup(nav.group),
                          'text-green-400': isActiveGroup(nav.group),
                        })}
                        onClick={() => { handleNav(nav); }}
                      >
                        <span key={`main-${nav.title}`}>{nav.title}</span>
                        <ChevronDownIcon />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!isMediumScreen && (
              <div className="flex">
                <div className="mr-4">
                  <Button to="/demo/" variant="primary" size="md" className='bg-[#1EC7A5] border-r-none border-0'>
                    Request a Demo
                  </Button>
                </div>
                <div>
                  <Button to="/contact/" variant="secondary" size="md" className='bg-white border-r-none text-black border border-solid border-black'>
                    Try Us
                  </Button>
                </div>
              </div>
            )}
            {isMediumScreen && (
              <MenuButton
                onClick={() => { toggleMenu() }}
                isActive={menuOpen}
              />
            )}
          </div>
        </div>
        {!isMediumScreen && (
          <AnimatePresence>
            {activeGroup && (
              <motion.div
                className="bg-white mt-24 absolute top-0 w-full shadow-xl"
                initial="enter"
                animate="idle"
                exit="exit"
                variants={navVariants}
                transition={transition}
              >
                <div className="container-lg">
                  <div className="h-px bg-gray-400" />
                  <div className="py-16">
                    {isActiveGroup(platformNavs.id) && (
                      <motion.div
                        className="row"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-4">
                          <motion.h2 variants={itemVariants} className="typo-h2">
                            <Link to="/platform/" onClick={closeMenu} className="no-underline inline-flex items-center group">
                              {platformNavs.title}
                              <span className="ml-4 opacity-0 group-hover:opacity-100 duration-300">
                                <ArrowRightIcon className="w-8 h-8" />
                              </span>
                            </Link>
                          </motion.h2>
                          <ul className="mt-12">
                            {platformNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                    {isActiveGroup(solutionNavs.id) && (
                      <motion.div
                        className="row"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-4">
                          <motion.h2 variants={itemVariants} className="typo-h2">
                            Solution Overview
                          </motion.h2>
                          <ul className="mt-12">
                            {solutionNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                        <div className="col-4">
                          <motion.h2 variants={itemVariants} className="typo-h2">
                            Industry
                          </motion.h2>
                          <ul className="mt-12">
                            {industryNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                    {isActiveGroup(resourcesNavs.id) && (
                      <motion.div
                        className="row"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-4">
                          <motion.h2 variants={itemVariants} className="typo-h2">
                            {resourcesNavs.title}
                          </motion.h2>
                          <ul className="mt-12">
                            {resourcesNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                    {isActiveGroup(companyNavs.id) && (
                      <motion.div
                        className="row"
                        variants={navVariants}
                        exit="exit"
                        animate="idle"
                        initial="enter"
                      >
                        <div className="col-5">
                          <motion.h2 variants={itemVariants} className="typo-h2">
                            {companyNavs.title}
                          </motion.h2>
                          <ul className="grid grid-rows-3 grid-cols-2 grid-flow-col mt-12">
                            {companyNavs.items.map((nav) => (
                              <NavLink to={nav.url} key={nav.title}>
                                {nav.title}
                              </NavLink>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        )}
        {isMediumScreen && (
          <motion.div className="bg-white fixed w-full sm:w-96 top-0 right-0 h-screen z-50"
            variants={sidebarVariants}
            animate={menuOpen ? 'menuOpen' : 'menuClosed'}
            initial={false}
          >
            <div className="flex flex-col h-full">
              <div className="px-4 flex items-center justify-between h-24">
                <div>
                  <Link to="/" onClick={() => { closeMenu(); }} className="block uppercase">
                    <img src={LogoImageSrc} alt="Logo" className="h-8" />
                  </Link>
                </div>
                <MenuButton
                  onClick={() => { closeMenu() }}
                  isActive={menuOpen}
                />
              </div>
              <div className="flex-1 overflow-y-auto px-4 md:px-8">
                {isActiveGroup(null) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    {mainNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        {nav.url && (
                          <Link to={nav.url} className="typo-blockquote no-underline">
                            {nav.title}
                          </Link>
                        )}
                        {nav.group && (
                          <span
                            onClick={() => { handleNav(nav); }}
                            className="typo-blockquote flex items-center justify-between cursor-pointer"
                            key={`main-${nav.title}`}
                          >
                            {nav.title}
                            <ChevronRightIcon />
                          </span>
                        )}
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(platformNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li
                      variants={itemVariants}
                      className="mt-4"
                    >
                      <span
                        onClick={() => { handleNav({ group: null }); }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {platformNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span onClick={() => { handleNav(nav) }} className="typo-blockquote no-underline">
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(solutionNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav mb-8"
                  >
                    <motion.li
                      variants={itemVariants}
                      className="mt-4"
                    >
                      <span
                        onClick={() => { handleNav({ group: null }); }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    <motion.li
                      variant={itemVariants}
                      className="mt-4"
                    >
                      <span className="typo-body">
                        {solutionNavs.title}
                      </span>
                    </motion.li>
                    {solutionNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span onClick={() => { handleNav(nav) }} className="typo-blockquote no-underline">
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                    <motion.li
                      variant={itemVariants}
                      className="mt-8"
                    >
                      <span className="typo-body">
                        {industryNavs.title}
                      </span>
                    </motion.li>
                    {industryNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span onClick={() => { handleNav(nav) }} className="typo-blockquote no-underline">
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(resourcesNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li
                      variants={itemVariants}
                      className="mt-4"
                    >
                      <span
                        onClick={() => { handleNav({ group: null }); }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {resourcesNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span onClick={() => { handleNav(nav) }} className="typo-blockquote no-underline">
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
                {isActiveGroup(companyNavs.id) && (
                  <motion.ul
                    variants={navVariants}
                    exit="exit"
                    animate="idle"
                    initial="enter"
                    className="divide-nav"
                  >
                    <motion.li
                      variants={itemVariants}
                      className="mt-4"
                    >
                      <span
                        onClick={() => { handleNav({ group: null }); }}
                        className="typo-body flex items-center cursor-pointer"
                      >
                        <ChevronLeftIcon />
                        Back
                      </span>
                    </motion.li>
                    {companyNavs.items.map((nav, index) => (
                      <motion.li
                        variants={itemVariants}
                        className="mt-4"
                        key={index}
                      >
                        <span onClick={() => { handleNav(nav) }} className="typo-blockquote no-underline">
                          {nav.title}
                        </span>
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </header>
    </>
  );
};

export default Header;
