import * as React from 'react';
import { Script } from 'gatsby';
import { LayoutProvider } from './Context';
import Header from './Header';
import Footer from './Footer';
import TopBanner from './TopBanner';
const isBrowser = typeof window !== "undefined"
const Layout = ({ children, location }) => {
  if (isBrowser && window?.gtag) {
    window.gtag("config", "G-48EN3TV3PE", {
      js: new Date(),
    });
  }
  return (
    <LayoutProvider location={location}>
      <Script type='text/javascript' id='hs-script-loader' async defer src='//js.hs-scripts.com/23632167.js' />
      <TopBanner />
      <Header location={location} />
      <main>
        {children}
      </main>
      <Footer />
    </LayoutProvider>
  );
};

export default Layout;
