import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';

import Button from '../components/Button';
import NewsletterForm from '../components/NewsletterForm';
import LinkedInIcon from '../components/icons/LinkedInIcon';
import MediumIcon from '../components/icons/MediumIcon';
import TwitterIcon from '../components/icons/TwitterIcon';
import Link from '../components/Link';
import SocialLink from '../components/SocialLink';

import LogoImageSrc from '../images/shared/logo.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      platformNavs: navigationYaml(yamlId: {eq: "/platform"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      solutionNavs: navigationYaml(yamlId: {eq: "/solutions"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      industryNavs: navigationYaml(yamlId: {eq: "/industry"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      resourcesNavs: navigationYaml(yamlId: {eq: "/resources"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
      companyNavs: navigationYaml(yamlId: {eq: "/company"}) {
        id: yamlId
        title
        items {
          title
          url
        }
      }
    }
  `);

  const {
    platformNavs,
    solutionNavs,
    industryNavs,
    resourcesNavs,
    companyNavs
  } = data;

  return (
    <>
      <footer className="bg-gray-100 py-20">
        <div className="container-lg">
          <div className="row">
            <div className="col-full lg:col-3">
              <Link to="/"  title="footer logo  Safelishare"  className="block uppercase">
                <img src={LogoImageSrc} alt="Logo" className="h-8" />
              </Link>
              <p className="typo-body mt-8">
                Subscribe now for our monthly news and trends updates.
              </p>
              <div className="mt-8">
                <NewsletterForm formId="5a641716-3c76-4d74-aefd-eeb0cc39ec4f" />
              </div>
              <div className="flex mt-4">
                <div className="mr-4">
                  <SocialLink url="https://twitter.com/safelishare">
                    <TwitterIcon />
                  </SocialLink>
                </div>
                <div className="mr-4">
                  <SocialLink url="https://linkedin.com/company/safelishare">
                    <LinkedInIcon />
                  </SocialLink>
                </div>
                <div className="mr-4">
                  <SocialLink url="https://medium.com/@safelishare">
                    <MediumIcon />
                  </SocialLink>
                </div>
              </div>
            </div>
            <div className="col-full lg:col-9">
              <div className="row">
                <div className="col-full sm:col-6 lg:col-4 mt-12 lg:mt-0">
                  <h2 className="typo-h3">
                    {companyNavs.title}
                  </h2>
                  <ul className="text-gray-500 mt-8">
                    {companyNavs.items.map((nav) => (
                      <li className="mt-2" key={nav.title}>
                        <Link to={nav.url} title={nav.title} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-12 lg:mt-0">
                  <h2 className="typo-h3">
                    {platformNavs.title}
                  </h2>
                  <ul className="text-gray-500 mt-8">
                    {platformNavs.items.map((nav) => (
                      <li className="mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-12 lg:mt-0">
                  <h2 className="typo-h3">
                    {solutionNavs.title}
                  </h2>
                  <ul className="text-gray-500 mt-8">
                    {solutionNavs.items.map((nav) => (
                      <li className="mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-12 lg:mt-0">
                  <h2 className="typo-h3">
                    {resourcesNavs.title}
                  </h2>
                  <ul className="text-gray-500 mt-8">
                    {resourcesNavs.items.map((nav) => (
                      <li className="mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-full sm:col-6 lg:col-4 mt-12 lg:mt-0">
                  <h2 className="typo-h3">
                    {industryNavs.title}
                  </h2>
                  <ul className="text-gray-500 mt-8">
                    {industryNavs.items.map((nav) => (
                      <li className="mt-2" key={nav.title}>
                        <Link to={nav.url} className="typo-body no-underline">
                          {nav.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center md:justify-between mt-12">
            <div className="w-full md:w-auto text-center">
              <small className="typo-small text-gray-500">
                2023 SafeLiShare, Inc., All rights reserved.
              </small>
            </div>
            <div className="flex flex-col md:flex-row text-center flex-wrap items-center mt-4 md:mt-0">
              <div className="flex items-center">
                <Link to="/privacy-policy/" title="privacy-policy | Safelishare" className="typo-small text-gray-500 no-underline">
                  Privacy Policy
                </Link>
                <span className="inline-block h-3 w-px bg-gray-500 mx-2" />
                <Link to="/cookie-policy/" title="cookie-policy | Safelishare" className="typo-small text-gray-500 no-underline mr-4">
                  Cookie Policy
                </Link>
              </div>
              <div className="flex mt-4 md:mt-0">
                <div className="mr-4">
                  <Button to="/demo/" title="Request a Demo | Safelishare" variant="primary" size="md" className="bg-black border-r-none border-0 font-normal">
                    Request a Demo
                  </Button>
                </div>
                <div>
                  <Button to="/contact/" title="Contact Us | Safelishare" variant="secondary" size="md" className="bg-white border-r-none text-black border border-solid border-black">
                    Contact Us
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
