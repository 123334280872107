exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-confidential-rag-jsx": () => import("./../../../src/pages/ConfidentialRAG.jsx" /* webpackChunkName: "component---src-pages-confidential-rag-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-data-privacy-resources-jsx": () => import("./../../../src/pages/data-privacy-resources.jsx" /* webpackChunkName: "component---src-pages-data-privacy-resources-jsx" */),
  "component---src-pages-eula-jsx": () => import("./../../../src/pages/eula.jsx" /* webpackChunkName: "component---src-pages-eula-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-onfidential-rag-jsx": () => import("./../../../src/pages/onfidentialRAG.jsx" /* webpackChunkName: "component---src-pages-onfidential-rag-jsx" */),
  "component---src-pages-our-team-jsx": () => import("./../../../src/pages/our-team.jsx" /* webpackChunkName: "component---src-pages-our-team-jsx" */),
  "component---src-pages-platform-jsx": () => import("./../../../src/pages/platform.jsx" /* webpackChunkName: "component---src-pages-platform-jsx" */),
  "component---src-pages-rsac-2023-jsx": () => import("./../../../src/pages/rsac-2023.jsx" /* webpackChunkName: "component---src-pages-rsac-2023-jsx" */),
  "component---src-pages-rsac-2024-jsx": () => import("./../../../src/pages/rsac-2024.jsx" /* webpackChunkName: "component---src-pages-rsac-2024-jsx" */),
  "component---src-pages-solution-cloud-privacy-vault-jsx": () => import("./../../../src/pages/solution/cloud-privacy-vault.jsx" /* webpackChunkName: "component---src-pages-solution-cloud-privacy-vault-jsx" */),
  "component---src-pages-solution-confidential-ai-jsx": () => import("./../../../src/pages/solution/confidential-ai.jsx" /* webpackChunkName: "component---src-pages-solution-confidential-ai-jsx" */),
  "component---src-pages-solution-confidential-clean-room-jsx": () => import("./../../../src/pages/solution/confidential-clean-room.jsx" /* webpackChunkName: "component---src-pages-solution-confidential-clean-room-jsx" */),
  "component---src-pages-solution-continuous-confidential-access-jsx": () => import("./../../../src/pages/solution/continuous-confidential-access.jsx" /* webpackChunkName: "component---src-pages-solution-continuous-confidential-access-jsx" */),
  "component---src-pages-solution-esports-jsx": () => import("./../../../src/pages/solution/esports.jsx" /* webpackChunkName: "component---src-pages-solution-esports-jsx" */),
  "component---src-pages-solution-financial-services-jsx": () => import("./../../../src/pages/solution/financial-services.jsx" /* webpackChunkName: "component---src-pages-solution-financial-services-jsx" */),
  "component---src-pages-solution-healthcare-jsx": () => import("./../../../src/pages/solution/healthcare.jsx" /* webpackChunkName: "component---src-pages-solution-healthcare-jsx" */),
  "component---src-pages-solution-index-jsx": () => import("./../../../src/pages/solution/index.jsx" /* webpackChunkName: "component---src-pages-solution-index-jsx" */),
  "component---src-pages-solution-insurance-jsx": () => import("./../../../src/pages/solution/insurance.jsx" /* webpackChunkName: "component---src-pages-solution-insurance-jsx" */),
  "component---src-pages-solution-manufacturing-jsx": () => import("./../../../src/pages/solution/manufacturing.jsx" /* webpackChunkName: "component---src-pages-solution-manufacturing-jsx" */),
  "component---src-pages-solution-pharmaceutical-jsx": () => import("./../../../src/pages/solution/pharmaceutical.jsx" /* webpackChunkName: "component---src-pages-solution-pharmaceutical-jsx" */),
  "component---src-pages-solution-privacy-data-collaboration-jsx": () => import("./../../../src/pages/solution/privacy-data-collaboration.jsx" /* webpackChunkName: "component---src-pages-solution-privacy-data-collaboration-jsx" */),
  "component---src-pages-solution-runtime-data-security-services-jsx": () => import("./../../../src/pages/solution/runtime-data-security-services.jsx" /* webpackChunkName: "component---src-pages-solution-runtime-data-security-services-jsx" */),
  "component---src-pages-solution-saas-data-security-jsx": () => import("./../../../src/pages/solution/saas-data-security.jsx" /* webpackChunkName: "component---src-pages-solution-saas-data-security-jsx" */),
  "component---src-pages-solution-semiconductor-data-jsx": () => import("./../../../src/pages/solution/semiconductor-data.jsx" /* webpackChunkName: "component---src-pages-solution-semiconductor-data-jsx" */),
  "component---src-pages-webinars-jsx": () => import("./../../../src/pages/webinars.jsx" /* webpackChunkName: "component---src-pages-webinars-jsx" */),
  "component---src-pages-whitepaper-jsx": () => import("./../../../src/pages/whitepaper.jsx" /* webpackChunkName: "component---src-pages-whitepaper-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-ai-data-and-privacy-preservation-trends-and-news-from-rsa-2023-related-to-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/ai-data-and-privacy-preservation-trends-and-news-from-rsa-2023-related-to-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-ai-data-and-privacy-preservation-trends-and-news-from-rsa-2023-related-to-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-building-multi-cloud-confidential-computing-the-danger-of-cloud-and-data-lock-in-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/building-multi-cloud-confidential-computing-the-danger-of-cloud-and-data-lock-in/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-building-multi-cloud-confidential-computing-the-danger-of-cloud-and-data-lock-in-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-al-clean-room-at-aws-marketplace-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/confidential-al-clean-room-at-aws-marketplace/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-al-clean-room-at-aws-marketplace-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-computing-saves-the-game-and-gamers-pii-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/confidential-computing-saves-the-game-and-gamers-pii/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-computing-saves-the-game-and-gamers-pii-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-computing-transformative-solution-for-data-and-workload-sharing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/confidential-computing-transformative-solution-for-data-and-workload-sharing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-confidential-computing-transformative-solution-for-data-and-workload-sharing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-data-security-breach-in-2023-to-date-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/data-security-breach-in-2023-to-date/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-data-security-breach-in-2023-to-date-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-decentralized-and-distributed-authorization-explained-in-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/decentralized-and-distributed-authorization-explained-in-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-decentralized-and-distributed-authorization-explained-in-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-defining-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/defining-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-defining-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-empower-your-algorithms-reducing-bias-while-safeguarding-data-privacy-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/empower-your-algorithms-reducing-bias-while-safeguarding-data-privacy/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-empower-your-algorithms-reducing-bias-while-safeguarding-data-privacy-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-ai-governance-is-making-data-sharing-safer-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-ai-governance-is-making-data-sharing-safer/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-ai-governance-is-making-data-sharing-safer-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-key-benefits-of-data-sharing-in-industry-4-0-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/key-benefits-of-data-sharing-in-industry-4-0/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-key-benefits-of-data-sharing-in-industry-4-0-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-privacy-preserving-software-as-a-service-with-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/privacy-preserving-software-as-a-service-with-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-privacy-preserving-software-as-a-service-with-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-protecting-sensitive-records-through-encrypted-data-rooms-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/protecting-sensitive-records-through-encrypted-data-rooms/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-protecting-sensitive-records-through-encrypted-data-rooms-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-put-zero-trust-architecture-in-realization-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/put-zero-trust-architecture-in-realization/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-put-zero-trust-architecture-in-realization-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-saas-data-security-how-to-ensure-your-data-is-safe-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/saas-data-security-how-to-ensure-your-data-is-safe/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-saas-data-security-how-to-ensure-your-data-is-safe-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-announces-issuance-of-three-new-us-patents-in-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-announces-issuance-of-three-new-us-patents-in-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-announces-issuance-of-three-new-us-patents-in-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-announces-safelishare-clean-rooms-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-announces-safelishare-clean-rooms/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-announces-safelishare-clean-rooms-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-confidential-ai-to-set-llm-access-boundary-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-confidential-ai-to-set-llm-access-boundary/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-confidential-ai-to-set-llm-access-boundary-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-empowers-model-and-data-marketplaces-with-confidential-clean-rooms-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-empowers-model-and-data-marketplaces-with-confidential-clean-rooms/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-empowers-model-and-data-marketplaces-with-confidential-clean-rooms-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-inc-introduces-revolutionary-confidential-ai-governance-platform-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-inc-introduces-revolutionary-confidential-ai-governance-platform/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-inc-introduces-revolutionary-confidential-ai-governance-platform-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-introduces-confidential-rag-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-introduces-confidential-rag/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-introduces-confidential-rag-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-secure-data-platform-now-runs-on-confidential-containers-in-microsoft-azure-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/safelishare-secure-data-platform-now-runs-on-confidential-containers-in-microsoft-azure/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-safelishare-secure-data-platform-now-runs-on-confidential-containers-in-microsoft-azure-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-secure-application-runtime-environments-with-secure-enclaves-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/secure-application-runtime-environments-with-secure-enclaves/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-secure-application-runtime-environments-with-secure-enclaves-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-secure-generative-ai-llm-adoption-using-confidential-computing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/secure-generative-ai-llm-adoption-using-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-secure-generative-ai-llm-adoption-using-confidential-computing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-securing-edge-to-edge-chain-of-custody-in-augmented-and-predictive-analytics-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/securing-edge-to-edge-chain-of-custody-in-augmented-and-predictive-analytics/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-securing-edge-to-edge-chain-of-custody-in-augmented-and-predictive-analytics-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-tamper-proof-pharmaceutical-data-sharing-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/tamper-proof-pharmaceutical-data-sharing/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-tamper-proof-pharmaceutical-data-sharing-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-top-considerations-for-addressing-risks-in-the-owasp-top-10-for-llms-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/top-considerations-for-addressing-risks-in-the-owasp-top-10-for-llms/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-top-considerations-for-addressing-risks-in-the-owasp-top-10-for-llms-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-using-homomorphic-encryption-for-data-sharing-with-privacy-across-clouds-and-why-not-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/using-homomorphic-encryption-for-data-sharing-with-privacy-across-clouds-and-why-not/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-using-homomorphic-encryption-for-data-sharing-with-privacy-across-clouds-and-why-not-index-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-what-is-application-identity-in-confidential-computing-and-why-does-it-matter-index-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/what-is-application-identity-in-confidential-computing-and-why-does-it-matter/index.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-what-is-application-identity-in-confidential-computing-and-why-does-it-matter-index-md" */),
  "component---src-templates-event-jsx-content-file-path-src-content-events-data-a-i-summit-by-databricks-index-md": () => import("./../../../src/templates/event.jsx?__contentFilePath=/opt/build/repo/src/content/events/data-aI-summit-by-databricks/index.md" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-src-content-events-data-a-i-summit-by-databricks-index-md" */),
  "component---src-templates-event-jsx-content-file-path-src-content-events-healthcare-na-mar-24-02-2024-index-md": () => import("./../../../src/templates/event.jsx?__contentFilePath=/opt/build/repo/src/content/events/healthcare-na-mar-24-02-2024/index.md" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-src-content-events-healthcare-na-mar-24-02-2024-index-md" */),
  "component---src-templates-event-jsx-content-file-path-src-content-events-rsac-2024-index-md": () => import("./../../../src/templates/event.jsx?__contentFilePath=/opt/build/repo/src/content/events/rsac-2024/index.md" /* webpackChunkName: "component---src-templates-event-jsx-content-file-path-src-content-events-rsac-2024-index-md" */),
  "component---src-templates-form-jsx-content-file-path-src-content-forms-all-in-one-governance-platform-for-ai-and-llms-index-md": () => import("./../../../src/templates/form.jsx?__contentFilePath=/opt/build/repo/src/content/forms/all-in-one-governance-platform-for-ai-and-llms/index.md" /* webpackChunkName: "component---src-templates-form-jsx-content-file-path-src-content-forms-all-in-one-governance-platform-for-ai-and-llms-index-md" */),
  "component---src-templates-form-jsx-content-file-path-src-content-forms-cisos-guide-confidential-computing-index-md": () => import("./../../../src/templates/form.jsx?__contentFilePath=/opt/build/repo/src/content/forms/cisos-guide-confidential-computing/index.md" /* webpackChunkName: "component---src-templates-form-jsx-content-file-path-src-content-forms-cisos-guide-confidential-computing-index-md" */),
  "component---src-templates-form-jsx-content-file-path-src-content-forms-confidential-clean-rooms-use-cases-index-md": () => import("./../../../src/templates/form.jsx?__contentFilePath=/opt/build/repo/src/content/forms/confidential-clean-rooms-use-cases/index.md" /* webpackChunkName: "component---src-templates-form-jsx-content-file-path-src-content-forms-confidential-clean-rooms-use-cases-index-md" */),
  "component---src-templates-form-jsx-content-file-path-src-content-forms-demo-index-md": () => import("./../../../src/templates/form.jsx?__contentFilePath=/opt/build/repo/src/content/forms/demo/index.md" /* webpackChunkName: "component---src-templates-form-jsx-content-file-path-src-content-forms-demo-index-md" */),
  "component---src-templates-form-jsx-content-file-path-src-content-forms-ebook-llm-and-ai-governance-index-md": () => import("./../../../src/templates/form.jsx?__contentFilePath=/opt/build/repo/src/content/forms/ebook-llm-and-ai-governance/index.md" /* webpackChunkName: "component---src-templates-form-jsx-content-file-path-src-content-forms-ebook-llm-and-ai-governance-index-md" */),
  "component---src-templates-info-jsx-content-file-path-src-content-info-cookie-policy-index-md": () => import("./../../../src/templates/info.jsx?__contentFilePath=/opt/build/repo/src/content/info/cookie-policy/index.md" /* webpackChunkName: "component---src-templates-info-jsx-content-file-path-src-content-info-cookie-policy-index-md" */),
  "component---src-templates-info-jsx-content-file-path-src-content-info-privacy-policy-index-md": () => import("./../../../src/templates/info.jsx?__contentFilePath=/opt/build/repo/src/content/info/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-info-jsx-content-file-path-src-content-info-privacy-policy-index-md" */),
  "component---src-templates-job-jsx-content-file-path-src-content-jobs-devops-engineer-index-md": () => import("./../../../src/templates/job.jsx?__contentFilePath=/opt/build/repo/src/content/jobs/devops-engineer/index.md" /* webpackChunkName: "component---src-templates-job-jsx-content-file-path-src-content-jobs-devops-engineer-index-md" */),
  "component---src-templates-job-jsx-content-file-path-src-content-jobs-regional-account-executive-index-md": () => import("./../../../src/templates/job.jsx?__contentFilePath=/opt/build/repo/src/content/jobs/regional-account-executive/index.md" /* webpackChunkName: "component---src-templates-job-jsx-content-file-path-src-content-jobs-regional-account-executive-index-md" */),
  "component---src-templates-job-jsx-content-file-path-src-content-jobs-sales-development-representive-index-md": () => import("./../../../src/templates/job.jsx?__contentFilePath=/opt/build/repo/src/content/jobs/sales-development-representive/index.md" /* webpackChunkName: "component---src-templates-job-jsx-content-file-path-src-content-jobs-sales-development-representive-index-md" */),
  "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-ai-models-under-attack-conventional-controls-are-not-enough-index-md": () => import("./../../../src/templates/webinar.jsx?__contentFilePath=/opt/build/repo/src/content/webinars/ai-models-under-attack-conventional-controls-are-not-enough/index.md" /* webpackChunkName: "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-ai-models-under-attack-conventional-controls-are-not-enough-index-md" */),
  "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-demystifying-safelishare-clean-rooms-index-md": () => import("./../../../src/templates/webinar.jsx?__contentFilePath=/opt/build/repo/src/content/webinars/demystifying-safelishare-clean-rooms/index.md" /* webpackChunkName: "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-demystifying-safelishare-clean-rooms-index-md" */),
  "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-secure-sensitive-model-and-data-through-ml-pipeline-02-2024-index-md": () => import("./../../../src/templates/webinar.jsx?__contentFilePath=/opt/build/repo/src/content/webinars/secure-sensitive-model-and-data-through-ml-pipeline-02-2024/index.md" /* webpackChunkName: "component---src-templates-webinar-jsx-content-file-path-src-content-webinars-secure-sensitive-model-and-data-through-ml-pipeline-02-2024-index-md" */)
}

